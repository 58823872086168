import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import CenterColumn from '../components/center-column/center-column';
import MinecraftPlaceGraph from '../components/minecraft-place-graph/minecraft-place-graph';
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs';

import { Heading, Text, Tabs, Tab } from 'grommet';
import PlacesList from '../components/places-list/places-list';
import MinecraftPlacePreview from '../components/minecraft-place-preview/minecraft-place-preview';

export const query = graphql`
  query MinecraftWorldTemplateQuery($id: String!) {
    world: sanityMinecraftWorld(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      places {
        id
        title
        xCoordinate
        zCoordinate
        yCoordinate
        mainImage {
          ...Image
        }
        slug {
          current
        }
      }
    }
  }
`;

const MinecraftWorldPage = ({ data, errors, pageContext }) => {
  const world = data?.world;
  return (
    <Layout>
      <Breadcrumbs
        items={[
          {
            title: 'Minecraft',
            href: '/minecraft'
          }
        ]}
        section={pageContext.section}
      />
      <CenterColumn>
        {errors && <SEO title="GraphQL Error" />}
        {world && <SEO title={world.title || 'Untitled'} />}

        <div>
          <Heading level="2">{world.title}</Heading>
        </div>
        {world.places && (
          <Tabs alignControls="start">
            <Tab title="Places">
              <PlacesList
                places={world.places}
                PlacePreviewComponent={MinecraftPlacePreview}
              />
            </Tab>
            <Tab title="Plot - Overworld">
              <MinecraftPlaceGraph
                places={world.places}
                title="Bases, locations and landmarks in the Overworld"
              />
            </Tab>
            <Tab title="Plot - Nether">
              <MinecraftPlaceGraph
                places={world.places}
                nether={true}
                title="Bases, locations and landmarks in the Nether"
              />
            </Tab>
          </Tabs>
        )}
        {!world.places && <Text>Coming soon!</Text>}
      </CenterColumn>
    </Layout>
  );
};

export default MinecraftWorldPage;
