import React from 'react';
import {
  VictoryChart,
  VictoryScatter,
  VictoryLegend,
  VictoryTheme,
  VictoryLabel,
  foreignObject,
  VictoryTooltip
} from 'victory';
import {
  convertCoordinatesToNether,
  slugToUrl,
  buildImageObj
} from '../../lib/helpers';
import victoryTheme from '../../theme/victory';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Anchor,
  Image,
  Box,
  Text
} from 'grommet';
import { navigate } from 'gatsby';
import { imageUrlFor } from '../../lib/image-url';

const MinecraftPlaceGraph = ({ places, nether = false, title }) => {
  const data = places.map((place) => ({
    x: place.xCoordinate,
    y: place.zCoordinate,
    ...place
  }));

  return (
    <Box pad="medium" background={nether ? 'neutral-4' : 'neutral-1'}>
      <Text>
        <em>{title}</em>
      </Text>
      <VictoryChart
        theme={victoryTheme}
        padding={{ top: 10, bottom: 30, left: 50, right: 50 }}
      >
        <VictoryScatter
          style={{ data: { fill: '#FFCA58' } }}
          size={3}
          data={nether ? convertCoordinatesToNether(data) : data}
          labels={() => ' '}
          labelComponent={<VictoryTooltip flyoutComponent={<FlyOut />} />}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: 'data',
                      mutation: ({ datum }) => {
                        const url = slugToUrl(
                          datum.slug.current,
                          'minecraftPlace'
                        );
                        navigate(url);
                      }
                    }
                  ];
                }
              }
            }
          ]}
        />
      </VictoryChart>
    </Box>
  );
};

const FlyOut = ({ x, y, datum }) => (
  <foreignObject width="100%" height="100%" y={y - 50}>
    <div
      xmlns="http://www.w3.org/1999/xhtml"
      style={{
        position: 'static',
        backgroundColor: '#000',
        padding: '10px',
        fontSize: '8px',
        lineHeight: '100%'
      }}
    >
      <div>{datum.title}</div>
      <div>{`X: ${datum.x} / Y: ${datum.y}`}</div>
    </div>
  </foreignObject>
);

export default MinecraftPlaceGraph;
